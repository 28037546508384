/** 
 * Definição das chaves únicas usadas para armazenar o estado.
 * 
 * Cada chave deve ser única em todo o projeto. 
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const cmcStateKeys = Object.freeze({
  LISTED: "CMC_LISTED",
  IS_LISTING: "CMC_IS_LISTING",
  PAGE_STATES: "CMC_PAGE_STATE",
});
