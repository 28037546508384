import { cmcUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { CmcExcelFilterType } from '../types';
import { HttpStatusEnum } from '../../../../shared/enums';

/**
 * Download excel
 * 
 * @param {CmcExcelFilterType} data
 * @returns {Promise<string>}
 */
const execute = async (data) => {
  const isAsync = 1;
  const res = await httpClient.post(cmcUrls.BASE + '/excel/' + isAsync, data);

  switch (res.status) {
    case HttpStatusEnum.HTTP_CREATED: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };