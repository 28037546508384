import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { CmcListFilterType, cmcListPagerType, initCmcListPagerType } from "../types";
import { cmcStateKeys } from "./cmcStoreStateKeys";

/** @returns {CmcListPagerType} */
const getListed = () => store.getState(cmcStateKeys.LISTED) ?? initCmcListPagerType();

/** @returns {boolean} */
const getIsListing = () => store.getState(cmcStateKeys.IS_LISTING) ?? false;

/** 
 * @returns {{ 
 *   searchBarFilter: SearchBarFilterType, 
 *   filter: CmcListFilterType
 * }}
*/
const getPageState = () => store.getState(cmcStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getPageState,
};
