import cmcStoreActions from './cmcStoreActions';
import cmcStoreGetters from './cmcStoreGetters';
import cmcStoreMutations from './cmcStoreMutations';
import { cmcStateKeys } from "./cmcStoreStateKeys";

const cmcStore = {
  ...cmcStoreActions,
  ...cmcStoreGetters,
  ...cmcStoreMutations,
  cmcStateKeys,
};

export default cmcStore;