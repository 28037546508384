<template>
  <div>
    <div class="row">
      <ShadedTable
        :length="listed.items.length"
        :headers="headers"
        :loading="isListing"
      >
        <template v-for="(item, index) in listed.items" :slot="index">
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.company_plant_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.group_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.product_name }} | {{ item.supplier_name }} ({{
                item.origin_name
              }})
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ $helper.toMoney(item.price) }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.measured_unit_letter }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ $helper.parseDate(item.cmc_price_validity_start, 'DD/MM/YYYY') }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import cmcStore from "../../store";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

/** Store Getters */
const listed = computed(() => cmcStore.getListed());
const isListing = computed(() => cmcStore.getIsListing());

const headers = ref([
  "Central",
  "Categoria",
  "Grupo",
  "MCC",
  "Preço",
  "Unidade",
  "Data",
]);
</script>

<style scoped>
* {
  font-family: Fredoka;
}
</style>
