import { CmcListFilterType, CmcListPagerType } from "../types";
import { cmcListService, cmcDownloadExcelService } from "../services";
import mutations from "./cmcStoreMutations";
import getters from "./cmcStoreGetters";
import { listResources } from "../../../../shared/store";

/**
 * Listar
 *
 * @param {CmcListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?CmcListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  return await listResources(filter, isAccumulateItems, mutations, getters, cmcListService);
};

/**
 * Download excel
 *
 * @param {initCmcExcelFilterType} data
 * @returns {Promise<string>}
 */
async function downloadExcel(data) {
  return await cmcDownloadExcelService.execute(data);
}

export default {
  list,
  downloadExcel,
};
