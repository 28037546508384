import store from "../../../../shared/libraries/store";
import { cmcStateKeys } from "./cmcStoreStateKeys";
import { CmcListFilterType, CmcListPagerType } from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {CmcListPagerType} listed
 * @returns {CmcListPagerType}
 */
const setListed = (listed) => store.commit(cmcStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) => store.commit(cmcStateKeys.IS_LISTING, isListing);

/** 
 * @param {{ 
*   searchBarFilter: SearchBarFilterType, 
*   filter: CmcListFilterType
* }} filters
*/
const setPageState = (filters) => store.commit(cmcStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(cmcStateKeys));
}

/**
 * @param {CmcListPagerType} listed
 * @returns {CmcListPagerType}
 */
const setListMerge = (listed) => store.commitListMerge(cmcStateKeys, listed);

export default {
  setListed,
  setIsListing,
  setPageState,
  resetStates,
  setListMerge,
};
